<template>
  <div>
    <FilterGroup
      v-if="displayFilters"
      ref="filter-group"
      title="Filters"
      :target-audiences="targetAudiences"
      :issues="issues"
      @updated="updateFilters"
    />

    <ProjectCardList :items="filteredKits" @clearFilters="clearFilters" :likes="likes" />
  </div>
</template>

<script>
import axios from 'axios';
import FilterGroup from '@/components/project_page/FilterGroup.vue';
import ProjectCardList from '@/components/project_page/ProjectCardList.vue';

export default {
  name: 'ProjectIndexPage',
  components: {
    FilterGroup,
    ProjectCardList,
  },
  props: {
    pageId: {
      required: true,
      type: Number,
    },
    displayFilters: {
      type: Boolean,
      default: true,
    },
  },
  async mounted() {
    this.projectKits = await axios
      .get(`/api/v2/pages/?child_of=${this.pageId}&fields=*&type=projects.ProjectKitPage`)
      .then(({ data }) => data.items);
    this.targetAudiences = await axios.get('/api/v2/categories/target-audience/').then(({ data }) => data);
    this.issues = await axios.get('/api/v2/categories/issue/').then(({ data }) => data);
    this.fetchLikes();
  },
  data() {
    return {
      projectKits: [],
      targetAudiences: [],
      issues: [],
      filteredAudience: false,
      filteredIssue: false,
      likes: [],
    };
  },
  computed: {
    filteredKits() {
      if (!this.filteredIssue && !this.filteredAudience) {
        return this.projectKits;
      }
      const filtered = this.projectKits.filter((item) => {
        const issueMatch =
          item.issues.filter((issue) => issue.name === this.filteredIssue).length ||
          !this.filteredIssue;
        const audienceMatch =
          item.target_audiences.filter((audience) => audience.name === this.filteredAudience)
            .length || !this.filteredAudience;
        return issueMatch && audienceMatch;
      });

      return filtered;
    },
  },
  methods: {
    clearFilters() {
      this.$refs['filter-group'].clearFilters();
    },
    updateFilters(filters) {
      this.filteredIssue = filters.issue;
      this.filteredAudience = filters.audience;
    },
    /**
     * Like count for articles
     */
    fetchLikes() {
      axios
        .get('/feedback/was-it-helpful/pages/')
        .then(({ data }) => {
          this.likes = data;
        })
        .catch(() => {
          this.errorMessage = 'Error fetching likes';
        });
    },
  },
};
</script>
